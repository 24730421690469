import React from 'react';

import './UseCase.scss';

function UseCase({ title, text, githubLink, github, videoLink, video, tag }) {
  return (
    <div className="use-cases__item">
      <div className="use-cases__item-content">
        <p className="use-cases__item-title">
          {title}
        </p>
        <p className="use-cases__item-text">
          {text}
        </p>
      </div>
      <div className="use-cases__item-links">
        <a
          href={githubLink}
          className="use-cases__item-link use-cases__item-link--documentation"
        >
          {github}
        </a>
        <a
          href={videoLink}
          className="use-cases__item-link use-cases__item-link--study"
        >
          {video}
        </a>
      </div>
    </div>
  );
}

export default React.memo(UseCase);
