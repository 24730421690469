import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import UseCases from 'components/UseCases';

export default function UseCasePage({ pageContext: { useCases, common, links, navigation, tag } }) {
  return (
    <>
    <Helmet>
      <meta property='og:image' content='/seo/preview.jpg' />
      <meta property='og:image:width' content={820} />
      <meta property='og:image:height' content={540} />
    </Helmet>
    <Layout
      isHeaderTransparent
      withGradient={true}
      page="usecases"
      links={links}
      navigation={navigation}
      tag={tag}
      metaTitle='Use Cases | Reach Blockchain Development Platform'
    >
      <UseCases
        page="usecases"
        useCases={useCases}
        common={common}
        tag={tag}
      />
    </Layout>
    </>
  )
}
