import React from 'react';
import Slider from "react-slick";
import UseCase from 'components/UseCase';

import './UseCases.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

function UseCases({ page, common, useCases, tag }) {
  const sectionClass = !page ? 'use-cases' : 'use-cases use-cases--' + page;

  const renderUseCases = useCases.map((useCase, index) => (
    <UseCase
      title={useCase.title}
      text={useCase.short_description}
      githubLink={useCase.github_link}
      github={common.github}
      videoLink={useCase.video_link}
      video={common.video}
      tag={tag}
      key={index}
    />
  ));

  const sliderSettings = {
    className: 'center',
    centerMode: true,
    centerPadding: '16px',
    infinite: true,
    arrows: false
  };

  return (
    <section className={sectionClass}>
      <div className="container container--narrow use-cases__container">
        <p className="use-cases__title section-title">
          {common.use_cases_title_text}
        </p>
        <div className="use-cases__items">
          {renderUseCases}
        </div>
        <div className="use-cases__slider">
          <Slider {...sliderSettings}>
            {useCases.map((useCase, index) => (
              <UseCase
                title={useCase.title}
                text={useCase.short_description}
                githubLink={useCase.github_link}
                github={common.github}
                videoLink={useCase.video_link}
                video={common.video}
                tag={tag}
                key={index}
              />
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default React.memo(UseCases);
